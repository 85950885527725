import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  GetZonesByDeviceQuery,
  GetZonesByDeviceQueryVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { GET_ZONES_BY_DEVICE } from "../../../../../../../common/operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../../../../../common/variables/common";
import { errorNotification } from "../../../../../../../common/variables/notification";
import client from "../../../../../../../configs/apolloClient";

export const useGetZonesByDevice = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const { deviceId } = useParams();

  const { state } = useLocation();
  const selectedDeviceId = state?.deviceId ?? deviceId;

  useEffect(() => {
    if (selectedDeviceId && selectedCustomerId) {
      fetchZones(selectedDeviceId);

      return;
    }
  }, [selectedCustomerId, selectedDeviceId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getZonesByDevice, { data, loading, fetchMore }] = useLazyQuery<
    GetZonesByDeviceQuery,
    GetZonesByDeviceQueryVariables
  >(GET_ZONES_BY_DEVICE, {
    fetchPolicy: "network-only",
  });

  const fetchZones = (deviceId: string, nextToken?: string): void => {
    getZonesByDevice({
      variables: {
        limit: DEFAULT_LIMIT_COUNT,
        // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
        deviceId: selectedCustomerId + "#" + deviceId,
        customerId: selectedCustomerId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getZonesByDevice.nextToken) {
          fetchMore({
            variables: {
              limit: DEFAULT_LIMIT_COUNT,
              deviceId: selectedCustomerId + "#" + deviceId,
              customerId: selectedCustomerId,
              nextToken: response.data?.getZonesByDevice.nextToken,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev;
              }

              return {
                getZonesByDevice: {
                  ...fetchMoreResult.getZonesByDevice,
                  items: [
                    ...prev.getZonesByDevice.items,
                    ...fetchMoreResult.getZonesByDevice.items,
                  ],
                },
              };
            },
          });
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
