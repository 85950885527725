import {
  setMaterialColorToMesh,
  setMaterialColor,
  setMaterialOpacity,
} from "../SceneUtils/MaterilsUtils";

import { OBJECT_HIGHLIGHT_COLOR } from "../Constants";

class ObjectHighLight {
  constructor() {
    // Selected state.
    this.prevSelectedObjects = [];

    this.prevSelectedSide = {
      entity: null,
      color: null,
    };

    this.prevSelectedCamera = {
      entity: null,
      color: null,
    };

    // Hovered state.
    this.prevHoveredObject = {
      entity: null,
      color: null,
    };

    this.prevHoveredSide = {
      entity: null,
      color: null,
    };

    this.prevHoveredCamera = {
      entity: null,
      color: null,
    };
  }

  toggleHighlight(selected, hovered) {
    this.toggleHoveredHighlight(hovered);

    this.toggleSelectedHighlight(selected);
  }

  setEntityPrevState(stateName, entity) {
    if (!entity) {
      this[stateName].entity = null;

      this[stateName].color = null;
    } else {
      const modelWrapper = entity.getObjectByName(
        `${entity.EntityType}-ModelWrapper`
      );

      this[stateName].entity = entity;

      this[stateName].color =
        modelWrapper.children[0].children[0].material.color.clone();
    }
  }

  setSelectedObjectEntityPrevState(stateName, entities) {
    if (!entities) {
      this[stateName] = [];
    } else {
      this[stateName] = entities.map(entity => {
        const modelWrapper = entity.getObjectByName(
          `${entity.EntityType}-ModelWrapper`
        );

        const color =
          modelWrapper.children[0].children[0].material.color.clone();

        return { entity, color };
      });
    }
  }

  toggleEntityHighlight(entity, color, opacity = 1) {
    const modelWrapper = entity.getObjectByName(
      `${entity.EntityType}-ModelWrapper`
    );

    setMaterialColor(modelWrapper, color);

    setMaterialOpacity(modelWrapper, opacity);
  }

  toggleSelectedHighlight(selected) {
    const { selectedObjects, selectedTowerSide, selectedCamera } = selected;

    // Selected object.
    if (this.prevSelectedObjects.length) {
      this.prevSelectedObjects.forEach(item => {
        this.toggleEntityHighlight(item.entity, item.color);
      });
    }

    if (
      !!selectedObjects.length &&
      this.prevHoveredObject.entity &&
      selectedObjects.some(
        obj => obj.uuid === this.prevHoveredObject.entity.uuid
      )
    ) {
      this.toggleEntityHighlight(
        this.prevHoveredObject.entity,
        this.prevHoveredObject.color
      );

      this.setEntityPrevState("prevHoveredObject", null);
    }

    if (selectedObjects.length) {
      this.setSelectedObjectEntityPrevState(
        "prevSelectedObjects",
        selectedObjects
      );

      selectedObjects.forEach(entity => {
        this.toggleEntityHighlight(entity, OBJECT_HIGHLIGHT_COLOR);
      });
    } else {
      this.setSelectedObjectEntityPrevState("prevSelectedObjects", null);
    }

    // Selected side.
    if (this.prevSelectedSide.entity) {
      this.toggleEntityHighlight(
        this.prevSelectedSide.entity,
        this.prevSelectedSide.color,
        0
      );
    }

    if (
      selectedTowerSide &&
      this.prevHoveredSide.entity &&
      selectedTowerSide.uuid === this.prevHoveredSide.entity.uuid
    ) {
      this.toggleEntityHighlight(
        this.prevHoveredSide.entity,
        this.prevHoveredSide.color,
        0
      );

      this.setEntityPrevState("prevHoveredSide", null);
    }

    if (selectedTowerSide) {
      this.toggleEntityHighlight(
        selectedTowerSide,
        OBJECT_HIGHLIGHT_COLOR,
        0.6
      );

      this.setEntityPrevState("prevSelectedSide", selectedTowerSide);
    } else {
      this.setEntityPrevState("prevSelectedSide", null);
    }

    // Selected camera.
    if (this.prevSelectedCamera.entity) {
      this.toggleEntityHighlight(
        this.prevSelectedCamera.entity,
        this.prevSelectedCamera.color
      );
    }

    if (
      selectedCamera &&
      this.prevHoveredCamera.entity &&
      selectedCamera.uuid === this.prevHoveredCamera.entity.uuid
    ) {
      this.toggleEntityHighlight(
        this.prevHoveredCamera.entity,
        this.prevHoveredCamera.color
      );

      this.setEntityPrevState("prevHoveredCamera", null);
    }

    if (selectedCamera) {
      this.setEntityPrevState("prevSelectedCamera", selectedCamera);

      this.toggleEntityHighlight(selectedCamera, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.setEntityPrevState("prevSelectedCamera", null);
    }
  }

  toggleHoveredHighlight(hovered) {
    const { hoveredObject, hoveredTowerSide, hoveredCamera } = hovered;

    // Hovered object.
    if (this.prevHoveredObject.entity) {
      this.toggleEntityHighlight(
        this.prevHoveredObject.entity,
        this.prevHoveredObject.color
      );
    }

    if (
      hoveredObject &&
      !this.prevSelectedObjects.some(
        item => item.entity.uuid === hoveredObject.uuid
      )
    ) {
      this.setEntityPrevState("prevHoveredObject", hoveredObject);

      this.toggleEntityHighlight(hoveredObject, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.setEntityPrevState("prevHoveredObject", null);
    }

    // Hovered side.
    if (this.prevHoveredSide.entity) {
      this.toggleEntityHighlight(
        this.prevHoveredSide.entity,
        this.prevHoveredSide.color,
        0
      );
    }

    if (
      hoveredTowerSide &&
      (!this.prevSelectedSide.entity ||
        hoveredTowerSide.uuid !== this.prevSelectedSide.entity.uuid)
    ) {
      this.setEntityPrevState("prevHoveredSide", hoveredTowerSide);

      this.toggleEntityHighlight(hoveredTowerSide, OBJECT_HIGHLIGHT_COLOR, 0.6);
    } else {
      this.setEntityPrevState("prevHoveredSide", null);
    }

    // Hovered camera.
    if (this.prevHoveredCamera.entity) {
      this.toggleEntityHighlight(
        this.prevHoveredCamera.entity,
        this.prevHoveredCamera.color
      );
    }

    if (
      hoveredCamera &&
      (!this.prevSelectedCamera.entity ||
        hoveredCamera.uuid !== this.prevSelectedCamera.entity.uuid)
    ) {
      this.setEntityPrevState("prevHoveredCamera", hoveredCamera);

      this.toggleEntityHighlight(hoveredCamera, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.setEntityPrevState("prevHoveredCamera", null);
    }
  }
}

export default ObjectHighLight;
