import { useEffect, useRef, useState } from "react";

import { Layer, Stage } from "react-konva";
import { Box } from "@mui/material";

import Rectangle from "../../../../common/components/canvas/Rectangle";
import { AnnotationType } from "../../../../API";
import {
  PointsFormat,
  convertFloatsToIntegers,
} from "../../../../common/components/canvas/utils";
import KeyFramePlayer from "./KeyFramePlayer";
import useCreateFullAnnotationContainer from "../../../../common/components/canvas/useCreateFullAnnotationContainer";
import { SimpleAnnotation } from "../../UploadVideoGroupPage";
import FullPageLoader from "../../../../common/components/item/FullPageLoader";

type LeakFinderCanvasContainreProps = {
  imageSource: string;
  saveAnnotationsData: (points: PointsFormat) => void;
  focusAreaMarker: number | boolean;
  urlVideoLink: string;
  isChangeKeyFramePanelOpen: boolean;
  points: PointsFormat;
  allPoints: SimpleAnnotation[];
};

const LeakFinderCanvasContainer: React.FC<LeakFinderCanvasContainreProps> = ({
  urlVideoLink,
  saveAnnotationsData,
  isChangeKeyFramePanelOpen,
  focusAreaMarker,
  points,
  allPoints,
}) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState({ scaleX: 1, scaleY: 1 });

  const videoRef = useRef<HTMLVideoElement>(null);
  const initialRect = useRef({
    x: 50,
    y: 50,
    width: 100,
    height: 100,
    fill: "",
  });

  const editMode = typeof focusAreaMarker === "number";
  const addNewAnnotation = focusAreaMarker === true;

  const [rectangle, setRectangle] = useState(initialRect.current);

  const { fullAnnContainerRef } = useCreateFullAnnotationContainer(
    allPoints,
    scale.scaleX,
    scale.scaleY
  );

  useEffect(() => {
    const topLeft = rectangle;

    const topRight = {
      x: rectangle.x + rectangle.width,
      y: rectangle.y,
    };

    const bottomRight = {
      x: rectangle.x + rectangle.width,
      y: rectangle.y + rectangle.height,
    };

    const bottomLeft = {
      x: rectangle.x,
      y: rectangle.y + rectangle.height,
    };

    saveAnnotationsData([
      [topLeft.x * scale.scaleX, topLeft.y * scale.scaleY],
      [topRight.x * scale.scaleX, topRight.y * scale.scaleY],
      [bottomRight.x * scale.scaleX, bottomRight.y * scale.scaleY],
      [bottomLeft.x * scale.scaleX, bottomLeft.y * scale.scaleY],
    ]);

    setRectangle(initialRect.current);
  }, [addNewAnnotation]);

  useEffect(() => {
    if (editMode) {
      const editRect = { ...initialRect.current };

      const topLeft = {
        x: points[0][0] / scale.scaleX,
        y: points[0][1] / scale.scaleY,
      };
      const bottomRight = {
        x: points[2][0] / scale.scaleX,
        y: points[2][1] / scale.scaleY,
      };

      editRect.x = topLeft.x;

      editRect.y = topLeft.y;

      editRect.width = bottomRight.x - topLeft.x;

      editRect.height = bottomRight.y - topLeft.y;

      setRectangle(editRect);
    }
  }, [focusAreaMarker]);

  useEffect(() => {
    const updateSize = () => {
      if (videoRef?.current?.videoHeight) {
        const currentHeight = videoRef.current.clientHeight;
        const currentWidth = videoRef.current.clientWidth;

        const originalHeight = videoRef.current.videoHeight;
        const originalWidth = videoRef.current.videoWidth;

        setDimensions({
          width: currentWidth,
          height: currentHeight,
        });

        const scaleFactor = originalWidth / originalHeight;

        const scaleX = originalWidth / (currentHeight * scaleFactor);
        const scaleY = originalHeight / currentHeight;

        initialRect.current = {
          x: 50 / scaleFactor,
          y: 50 / scaleFactor,
          width: 200 / scaleFactor,
          height: 200 / scaleFactor,
          fill: "",
        };

        setRectangle(initialRect.current);

        setScale({ scaleX, scaleY });
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);

    if (videoRef.current) {
      resizeObserver.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        resizeObserver.unobserve(videoRef.current);
      }
    };
  }, []);

  const onChangeRectHandler = (newAttrs: typeof initialRect.current) => {
    setRectangle(newAttrs);

    const topLeft = newAttrs;

    const topRight = {
      x: newAttrs.x + newAttrs.width,
      y: newAttrs.y,
    };

    const bottomRight = {
      x: newAttrs.x + newAttrs.width,
      y: newAttrs.y + newAttrs.height,
    };

    const bottomLeft = {
      x: newAttrs.x,
      y: newAttrs.y + newAttrs.height,
    };

    const convertedPoints = convertFloatsToIntegers([
      [topLeft.x * scale.scaleX, topLeft.y * scale.scaleY],
      [topRight.x * scale.scaleX, topRight.y * scale.scaleY],
      [bottomRight.x * scale.scaleX, bottomRight.y * scale.scaleY],
      [bottomLeft.x * scale.scaleX, bottomLeft.y * scale.scaleY],
    ]);

    saveAnnotationsData(convertedPoints);
  };

  return (
    <>
      <Box
        sx={{
          visibility: videoRef?.current?.videoHeight ? "visible" : "hidden",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
          }}
        >
          <Stage width={dimensions.width} height={dimensions.height}>
            <Layer ref={fullAnnContainerRef} />

            {(addNewAnnotation || editMode) && (
              <Layer>
                <Rectangle
                  shapeProps={rectangle}
                  typeOfAnnotation={AnnotationType.FOCUS_AREA_LF}
                  onChange={onChangeRectHandler}
                />
              </Layer>
            )}
          </Stage>
        </Box>

        <Box sx={{ width: "100%", height: "100%" }}>
          <KeyFramePlayer
            videoRef={videoRef}
            state={isChangeKeyFramePanelOpen}
            urlVideoLink={urlVideoLink}
          />
        </Box>
      </Box>

      {!videoRef?.current?.videoHeight && <FullPageLoader />}
    </>
  );
};

export default LeakFinderCanvasContainer;
