import { useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ConfiguratorContainer from "../../../common/components/configurator/ConfiguratorContainer";
import ProjectViewPageHeader from "./ProjectViewPageHeader";
import Loader from "../../../common/components/loader/Loader";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useGetDTProject } from "../../../common/hooks/useGetDTProject";
import { useReadDataFromS3 } from "../../../common/hooks/useReadDataFromS3";
import { useSetObject3dModelAssets } from "../../../common/hooks/useSetObject3dModelAssets";
import { MeasurementSystemMode } from "../../../common/enums";

import {
  ProjectContext,
  ProjectObjects3dContext,
} from "../../../common/context";

import { ObjectDataInterface } from "../../../common/context/ProjectContext/ProjectEntitesTypes";
import { DTObject, DTProject } from "../../../../API";

const ProjectView = (): JSX.Element => {
  const navigate = useNavigate();

  const selectedCustomerId = useCustomerIdGuard();
  const { projectId } = useParams();

  const { projectData, setProjectData, clearProjectState } =
    useContext(ProjectContext);

  const { object3dData, loading: object3dLoading } = useContext(
    ProjectObjects3dContext
  );

  const { fetchProject, data, loading, error } = useGetDTProject();
  const { getDataFromS3, loading: getDataFromS3Loading } = useReadDataFromS3();
  const {
    getObject3dWithUpdatedModelAssets,
    loading: updateModelAssetsLoading,
  } = useSetObject3dModelAssets();

  const handleOpenProjectError = () => {
    navigate("/webgl-dashboard");
  };

  const handleSetProjectData = async (
    data: { getDTProject: DTProject },
    allDTObjects: DTObject[]
  ) => {
    const projectConfigResponse = await getDataFromS3(
      data.getDTProject.configURL
    );

    if (projectConfigResponse?.data) {
      const objectsWithUpdatedAssets = await getObject3dWithUpdatedModelAssets(
        projectConfigResponse.data.obj,
        allDTObjects
      );

      projectConfigResponse.data.obj = objectsWithUpdatedAssets.filter(
        (item: ObjectDataInterface) => item.modelAssets
      );

      setProjectData({
        configData: { ...projectConfigResponse?.data },
        settings: {
          ...data.getDTProject,
          measurementSystem:
            data.getDTProject.measurementSystem ||
            MeasurementSystemMode.Imperial,
        },
      });
    } else {
      handleOpenProjectError();
    }
  };

  useEffect(() => {
    if (projectId && selectedCustomerId) {
      fetchProject(selectedCustomerId, projectId.replace("_", "#"));
    } else {
      handleOpenProjectError();
    }

    return () => {
      clearProjectState();
    };
  }, [projectId]);

  useEffect(() => {
    if (data && object3dData && !projectData) {
      handleSetProjectData(data, object3dData);
    }
  }, [data, object3dData]);

  useEffect(() => {
    if (error) {
      handleOpenProjectError();
    }
  }, [error]);

  const projectLoaded =
    !loading &&
    !getDataFromS3Loading &&
    !updateModelAssetsLoading &&
    !object3dLoading &&
    data &&
    projectData;

  return (
    <Wrapper>
      {projectLoaded ? (
        <>
          <ProjectViewPageHeader dtProject={projectData.settings} />
          <ConfiguratorContainer />
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default ProjectView;

const Wrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
}));
