import { MouseEvent } from "react";
import { Box, Typography, styled, useTheme } from "@mui/material";

import {
  CollapseArrowSVG,
  IconCirclePlus,
  WarningIcon,
} from "../../../assets/icons/svgAssets";

interface TowerPanelListItemHeaderInterface {
  text: string;
  selected: boolean;
  open?: boolean;
  onCollapseIconClick?: (e: MouseEvent<HTMLElement>) => void;
  withCollapseIcon?: boolean;
  onItemClick: (e: MouseEvent<HTMLElement>) => void;
  withAddIcon?: boolean;
  onAddClick?: () => void;
  withWarningIcon?: boolean;
}

interface HeaderWrapperInterface {
  isselected: "true" | "false";
}

interface IconWrapperInterface {
  withtransform?: "true" | "false";
  isrotate?: "true" | "false";
  withhover?: "true" | "false";
}

const TowerPanelListItemHeader = ({
  text,
  withCollapseIcon,
  withAddIcon,
  withWarningIcon,
  open,
  selected,
  onCollapseIconClick,
  onItemClick,
  onAddClick,
}: TowerPanelListItemHeaderInterface) => {
  const theme = useTheme();

  const handleOnAddIconClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    e.stopPropagation();

    onAddClick && onAddClick();
  };

  const handleOnCollapseIconClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    e.stopPropagation();

    onCollapseIconClick && onCollapseIconClick(e);
  };

  return (
    <HeaderWrapper
      isselected={selected ? "true" : "false"}
      onClick={onItemClick}
    >
      {withCollapseIcon && (
        <IconWrapper
          onClick={handleOnCollapseIconClick}
          withhover="true"
          withtransform="true"
          isrotate={open ? "true" : "false"}
        >
          <CollapseArrowSVG />
        </IconWrapper>
      )}
      <Title variant="body14Medium">{text}</Title>
      <Separator />
      {withWarningIcon && (
        <IconWrapper>
          <WarningIcon fillColor={theme.palette.red.lighter} size={18} />
        </IconWrapper>
      )}
      {withAddIcon && (
        <IconWrapper onClick={handleOnAddIconClick} withhover="true">
          <IconCirclePlus size={18} />
        </IconWrapper>
      )}
    </HeaderWrapper>
  );
};

export default TowerPanelListItemHeader;

const HeaderWrapper = styled(Box)<HeaderWrapperInterface>(
  ({ theme, isselected }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    height: "24px",
    padding: "0px 6px 0px 6px",
    cursor: "pointer",
    borderRadius: "4px",
    border: "2px solid transparent",
    ...(isselected === "true" && {
      border: `2px solid ${theme.palette["tower-header-border"]}`,
    }),
  })
);

const Title = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  margin: "0px 8px",
  color: theme.palette["primary-text"],

  "&:hover": {
    textDecorationLine: "underline",
  },
}));

const Separator = styled(Box)(() => ({
  flexGrow: 1,
}));

const IconWrapper = styled(Box)<IconWrapperInterface>(
  ({ theme, withtransform, withhover, isrotate }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    ...(withhover === "true" && {
      path: {
        fill: theme.palette["grey-dark"],
      },

      "&:hover": {
        path: {
          fill: theme.palette["base-label"],
        },
      },
    }),
    "& svg": {
      fontSize: "14px",
    },

    ...(withtransform === "true" && {
      transform: isrotate === "true" ? "rotate(0deg)" : "rotate(270deg)",
    }),
  })
);
