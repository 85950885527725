import { splitArrayIntoChunks } from "../../utils";

const ELEVATION_LIST_LIMIT = 512;
const MAX_RETRIES = 3;
const BACKOFF_DELAY_MS = 1000;

const getElevationByLocationList = async (locationsList, retryCount = 0) => {
  // eslint-disable-next-line no-undef
  const elevator = new google.maps.ElevationService();

  try {
    const response = await elevator.getElevationForLocations({
      locations: locationsList,
    });

    return response.results;
  } catch (error) {
    if (
      error &&
      error.message.includes("OVER_QUERY_LIMIT") &&
      retryCount < MAX_RETRIES
    ) {
      const delay = BACKOFF_DELAY_MS * Math.pow(2, retryCount);

      await new Promise(resolve => setTimeout(resolve, delay));

      return await getElevationByLocationList(locationsList, retryCount + 1);
    } else {
      throw error;
    }
  }
};

export const getElevationToLocation = async (latitude, longitude) => {
  const elevationList = await getElevationByLocationList([
    {
      lat: latitude,
      lng: longitude,
    },
  ]);

  return elevationList[0].elevation;
};

export const setElevationToMultiLineString = async multilinestringList => {
  const getLocationListWithElevation = async lineList => {
    const locationsList = lineList.map(([longitude, latitude]) => ({
      lat: latitude,
      lng: longitude,
    }));

    const elevationList = await getElevationByLocationList(locationsList);

    return lineList.map(([longitude, latitude], i) => [
      longitude,
      latitude,
      elevationList[i].elevation,
    ]);
  };

  const getCoordinatesListWithElevation = async coordinatesList => {
    const lineList = coordinatesList.flat(1);

    const lineListInChunks = splitArrayIntoChunks(
      lineList,
      ELEVATION_LIST_LIMIT
    );

    const lineListWithElevationInChunks = await Promise.all(
      lineListInChunks.map(
        async chunk => await getLocationListWithElevation(chunk)
      )
    );

    const lineListWithElevation = lineListWithElevationInChunks.flat();

    let start = 0;

    return coordinatesList.map(line => {
      const newLine = lineListWithElevation.slice(start, start + line.length);

      start += line.length;

      return newLine;
    });
  };

  const multiLineStringWithElevation = [];

  for (const multilinestring of multilinestringList) {
    const coordinatesListWithElevation = await getCoordinatesListWithElevation(
      multilinestring.coordinates
    );

    multilinestring.coordinates = coordinatesListWithElevation;

    multiLineStringWithElevation.push(multilinestring);
  }

  return multiLineStringWithElevation;
};

export const setElevationToLatLonBoxGrid = async latLonBoxGridPointList => {
  const getLocationListWithElevation = async latLonBoxGridPointList => {
    const locationsList = latLonBoxGridPointList.map(
      ({ latitude, longitude }) => {
        return {
          lat: latitude,
          lng: longitude,
        };
      }
    );

    const elevationList = await getElevationByLocationList(locationsList);

    return latLonBoxGridPointList.map(({ longitude, latitude }, i) => [
      longitude,
      latitude,
      elevationList[i].elevation,
    ]);
  };

  if (latLonBoxGridPointList.length > ELEVATION_LIST_LIMIT) {
    const lineListInChunks = splitArrayIntoChunks(
      latLonBoxGridPointList,
      ELEVATION_LIST_LIMIT
    );

    const elevationListInChunks = await Promise.all(
      lineListInChunks.map(async chunk => {
        return await getLocationListWithElevation(chunk);
      })
    );

    return elevationListInChunks.flat();
  }

  return await getLocationListWithElevation(latLonBoxGridPointList);
};
