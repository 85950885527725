import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { ProjectContext } from "./ProjectContext";

import { ObjectMode, MeasurementSystemMode } from "../../enums";
import { EntityTypeEnum, ModeTypeEnum } from "./ProjectEntitesTypes";

import TowerSideData from "../../data/TowerSideData";

import { getUniqueValuesFromArray } from "../../utils";

export const ProjectContextProvider = ({ children }) => {
  const [threeScene, setThreeScene] = useState(null);
  const [sceneStarted, setSceneStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(ModeTypeEnum.standard);

  const [projectData, setProjectData] = useState();

  const [objects, setObjects] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [towers, setTowers] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [measurementSystem, setMeasurementSystem] = useState(
    MeasurementSystemMode.Imperial
  );

  const [transformControlMode, setTransformControlMode] = useState(
    ObjectMode.translateObject
  );

  const [isFPVModeUnlocked, setIsFPVModeUnlocked] = useState(false);
  const [distancesData, setDistancesData] = useState({});
  const [distanceMeasurementsMode, setDistanceMeasurementsMode] =
    useState(false);

  const [activeObjectsListId, setActiveObjectsListId] = useState([]);
  const [activeCameraId, setActiveCameraId] = useState(null);
  const [activeTowerSideIndex, setActiveTowerSideIndex] = useState(null);
  const [hoveredObjectId, setHoveredObjectId] = useState(null);
  const [hoveredCameraId, setHoveredCameraId] = useState(null);
  const [hoveredTowerSideIndex, setHoveredTowerSideIndex] = useState(null);

  const [activeZoneId, setActiveZoneId] = useState(null);

  const [secondaryCameraId, setSecondaryCameraId] = useState(null);
  const [secondaryViewType, setSecondaryViewType] = useState(null);

  const [sketchplanState, setSketchplanState] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectData) {
      setObjects(projectData.configData.obj || []);

      setTowers(projectData.configData.towers || []);

      setCameras(projectData.configData.cameras || []);

      setZoneList(projectData.configData.zoneList || []);

      setMeasurementSystem(
        projectData.settings.measurementSystem || MeasurementSystemMode.Imperial
      );
    }
  }, [projectData]);

  const addObject = assetData => {
    const newObjData = {
      name: "",
      ...assetData,
      id: uuid(),
      EntityType: EntityTypeEnum.Object,
    };

    setObjects(prev => [...prev, newObjData]);
  };

  const removeObject = idsToRemove => {
    setObjects(prev => prev.filter(obj => !idsToRemove.includes(obj.id)));
  };

  const updateObjectProperties = stateData => {
    setObjects(prev =>
      prev.map(obj => {
        const dataToUpdate = stateData.find(data => data.id === obj.id);

        if (dataToUpdate) {
          return {
            ...obj,
            ...dataToUpdate.state,
          };
        }

        return obj;
      })
    );
  };

  const addTower = data => {
    const newTower = {
      ...data,
      EntityType: EntityTypeEnum.Tower,
      sides: [...TowerSideData],
      id: uuid(),
    };

    setTowers(prev => [...prev, newTower]);
  };

  const removeTower = idsToRemove => {
    setZoneList(prev =>
      prev.filter(zone => !idsToRemove.includes(zone.towerId))
    );

    setCameras(prev =>
      prev.filter(camera => !idsToRemove.includes(camera.towerId))
    );

    setTowers(prev => prev.filter(tower => !idsToRemove.includes(tower.id)));
  };

  const updateTower = (idsToUpdate, data) => {
    setTowers(prev =>
      prev.map(tower => {
        if (idsToUpdate.includes(tower.id)) {
          return {
            ...tower,
            ...data,
          };
        }

        return tower;
      })
    );
  };

  const updateTowerProperties = stateData => {
    setTowers(prev =>
      prev.map(tower => {
        const dataToUpdate = stateData.find(data => data.id === tower.id);

        if (dataToUpdate) {
          return {
            ...tower,
            ...dataToUpdate.state,
          };
        }

        return tower;
      })
    );
  };

  const addCamera = data => {
    const newCamera = {
      id: uuid(),
      name: "",
      EntityType: EntityTypeEnum.Camera,
      ...data,
    };

    setCameras(prev => [...prev, newCamera]);
  };

  const removeCamera = id => {
    setZoneList(prev => prev.filter(zone => zone.cameraId !== id));

    setCameras(prev => prev.filter(camera => camera.id !== id));
  };

  const updateCamera = (id, data) => {
    setCameras(prev =>
      prev.map(camera => {
        if (camera.id === id) {
          return {
            ...camera,
            ...data,
          };
        }

        return camera;
      })
    );
  };

  const updateCameraProperties = (idsToUpdate, zoneId) => {
    if (!threeScene) {
      return;
    }

    const stateDataList = idsToUpdate
      .map(id => {
        const camera =
          threeScene.sceneFacility3dObjects.facilityCameras.getCameraById(id);

        if (camera) {
          const state =
            threeScene.sceneFacility3dObjects.facilityCameras.getCameraState(
              camera
            );

          return { id, state };
        }

        return null;
      })
      .filter(data => !!data);

    if (stateDataList.length) {
      setCameras(prev =>
        prev.map(camera => {
          const dataToUpdate = stateDataList.find(
            data => data.id === camera.id
          );

          if (dataToUpdate) {
            if (!zoneId && camera.height !== dataToUpdate.state.height) {
              setZoneListSaveState({ cameraId: camera.id, isSaved: false });
            }

            return {
              ...camera,
              ...dataToUpdate.state,
            };
          }

          return camera;
        })
      );

      if (zoneId) {
        const cameraId = zoneList.find(zone => zone.id === zoneId)?.cameraId;

        const dataToUpdate =
          cameraId && stateDataList.find(data => data.id === cameraId);

        if (dataToUpdate)
          updateZone(zoneId, {
            tilt: dataToUpdate.state?.verticalAngle,
            pan: dataToUpdate.state?.horizontalAngle,
            relativePan: dataToUpdate.state?.relativeHorizontalAngle,
            isSaved: true,
          });
      }
    }
  };

  const addZone = data => {
    const newZone = {
      id: uuid(),
      name: "New Zone",
      isSaved: true,
      ...data,
    };

    setZoneList(prev => [...prev, newZone]);

    handleSetActiveZoneId(newZone);
  };

  const removeZone = id => {
    setZoneList(prev => prev.filter(zone => zone.id !== id));
  };

  const updateZone = (id, data) => {
    setZoneList(prev =>
      prev.map(zone => {
        if (zone.id === id) {
          return {
            ...zone,
            ...data,
          };
        }

        return zone;
      })
    );
  };

  const setZoneListSaveState = data => {
    const { towerIds, cameraId, zoneId, isSaved } = data;

    setZoneList(prev =>
      prev.map(zone => {
        if (towerIds || cameraId || zoneId) {
          if (towerIds && towerIds.includes(zone.towerId)) {
            zone.isSaved = isSaved;
          } else if (cameraId && zone.cameraId === cameraId) {
            zone.isSaved = isSaved;
          } else if (zoneId && zone.id === zoneId) {
            zone.isSaved = isSaved;
          }
        } else {
          zone.isSaved = isSaved;
        }

        return zone;
      })
    );
  };

  const handleSetActiveObjectId = (id, isMultiple = false) => {
    if (!isMultiple || id) {
      setActiveObjectsListId(prev => {
        if (!id) {
          return [];
        }

        if (isMultiple) {
          const isObjectAlreadySelected = prev.includes(id);

          if (isObjectAlreadySelected) {
            return prev.filter(existId => existId !== id);
          }

          return [...prev, id];
        }

        return [id];
      });
    }

    handleSetActiveTowerSideIndex(null);
  };

  const handleSetActiveTowerSideIndex = index => {
    setActiveTowerSideIndex(index);

    handleSetActiveCameraId(null);
  };

  const handleSetActiveCameraId = id => {
    setActiveCameraId(id);

    handleSetActiveZoneId(null);

    setSecondaryCameraId(null);

    setSecondaryViewType(null);
  };

  const handleSetActiveZoneId = data => {
    setActiveZoneId(data && data.id);

    if (data && data.id) {
      setCameraParamsBySelectedZone(data);

      setSecondaryCameraId(data.cameraId);

      setSecondaryViewType(data.viewType);
    }
  };

  const handleSetHoveredObjectId = id => {
    setHoveredObjectId(id);
  };

  const handleSetHoveredTowerSideIndex = index => {
    setHoveredTowerSideIndex(index);
  };

  const handleSetHoveredCameraId = id => {
    setHoveredCameraId(id);
  };

  const updateObjectTowerState = idsToUpdate => {
    if (!idsToUpdate.length || !threeScene) {
      return;
    }

    const allObjectsToUpdate = idsToUpdate.map(id => {
      const object =
        threeScene.sceneFacility3dObjects.facilityObjects.getObjectById(id);
      const tower =
        threeScene.sceneFacility3dObjects.facilityTowers.getTowerById(id);

      return object || tower;
    });

    const sceneObjects = allObjectsToUpdate.filter(
      item => item.EntityType === EntityTypeEnum.Object
    );
    const sceneTowers = allObjectsToUpdate.filter(
      item => item.EntityType === EntityTypeEnum.Tower
    );

    if (sceneObjects.length) {
      const stateData = sceneObjects.map(object => {
        return {
          id: object.ID,
          state: threeScene.sceneFacility3dObjects.getObjectState(object),
        };
      });

      updateObjectProperties(stateData);
    }

    if (sceneTowers.length) {
      const stateData = sceneTowers.map(tower => {
        return {
          id: tower.ID,
          state: threeScene.sceneFacility3dObjects.getObjectState(tower),
        };
      });

      updateTowerProperties(stateData);

      updateCameraProperties(
        cameras.filter(c => idsToUpdate.includes(c.towerId)).map(c => c.id),
        activeZoneId
      );

      setZoneListSaveState({
        isSaved: false,
        towerIds: sceneTowers.map(t => t.ID),
      });
    }
  };

  const handleDeleteObjectTowerData = idsToRemove => {
    if (threeScene && threeScene.sceneControls.transformControl) {
      threeScene.sceneControls.transformControl.toggleControlledObject(null);
    }

    removeObject(idsToRemove);

    removeTower(idsToRemove);

    handleSetActiveObjectId(null);
  };

  const handleDeleteCameraData = id => {
    removeCamera(id);

    handleSetActiveCameraId(null);

    setSecondaryCameraId(null);

    setSecondaryViewType(null);
  };

  const handleDeleteZoneData = id => {
    removeZone(id);

    handleSetActiveZoneId(null);

    setSecondaryCameraId(null);

    setSecondaryViewType(null);
  };

  const handleSetDistancesData = item => {
    setDistancesData(prev => {
      return { ...prev, [item.id]: item };
    });
  };

  const handelSetInitialDistanceData = () => {
    setDistancesData({});
  };

  const setCameraParamsBySelectedZone = zoneData => {
    threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
      zoneData.cameraId,
      "verticalAngle",
      zoneData.tilt
    );

    threeScene.sceneFacility3dObjects.facilityCameras.updateCameraRotation(
      zoneData.cameraId,
      "horizontalAngle",
      zoneData.pan
    );

    updateCameraProperties([zoneData.cameraId], null);
  };

  const isProjectHasUnsavedZone = () => {
    return zoneList.length > 0 && zoneList.some(zone => !zone.isSaved);
  };

  const isConfigDataChanged = () => {
    const initialData = JSON.stringify({
      terrainData: projectData.configData.terrainData,
      obj: projectData.configData.obj,
      cameras: projectData.configData.cameras,
      towers: projectData.configData.towers,
      zoneList: projectData.configData.zoneList,
    });
    const currentData = JSON.stringify(getProjectConfigData());

    return initialData !== currentData;
  };

  const getProjectConfigData = () => {
    const savedData = {
      terrainData: projectData.configData.terrainData,
      obj: objects,
      cameras: cameras,
      towers: towers,
      zoneList: zoneList,
    };

    return savedData;
  };

  const getUsedObjectsAssetsIds = () => {
    return getUniqueValuesFromArray(
      objects.map(obj => obj.modelAssets.assetId)
    );
  };

  const clearProjectState = () => {
    handleSetActiveObjectId(null);

    handleSetActiveTowerSideIndex(null);

    handleSetActiveCameraId(null);

    handleSetActiveZoneId(null);

    setSecondaryCameraId(null);

    setSecondaryViewType(null);

    handleSetHoveredObjectId(null);

    handleSetHoveredTowerSideIndex(null);

    handleSetHoveredCameraId(null);

    handelSetInitialDistanceData();

    setCameras([]);

    setObjects([]);

    setTowers([]);

    setZoneList([]);

    setProjectData();

    setMode(ModeTypeEnum.standard);
  };

  const exitFromProjectConfigurator = () => {
    navigate("/webgl-dashboard");
  };

  const toggleSketchplanState = () => {
    setSketchplanState(!sketchplanState);

    threeScene.sceneFacilitySketchplan.toggleVisibility(!sketchplanState);
  };

  return (
    <ProjectContext.Provider
      value={{
        projectData,
        setProjectData,
        threeScene,
        setThreeScene,
        isLoading,
        setIsLoading,
        sceneStarted,
        setSceneStarted,
        objects,
        activeObjectsListId,
        handleSetActiveObjectId,
        activeTowerSideIndex,
        handleSetActiveTowerSideIndex,
        activeCameraId,
        handleSetActiveCameraId,
        secondaryCameraId,
        setSecondaryCameraId,
        secondaryViewType,
        setSecondaryViewType,
        hoveredObjectId,
        handleSetHoveredObjectId,
        hoveredTowerSideIndex,
        handleSetHoveredTowerSideIndex,
        hoveredCameraId,
        handleSetHoveredCameraId,
        addObject,
        cameras,
        addCamera,
        updateCamera,
        updateCameraProperties,
        towers,
        addTower,
        updateTower,
        updateObjectTowerState,
        handleDeleteObjectTowerData,
        handleDeleteCameraData,
        handleDeleteZoneData,
        zoneList,
        addZone,
        removeZone,
        updateZone,
        setZoneListSaveState,
        activeZoneId,
        handleSetActiveZoneId,
        clearProjectState,
        exitFromProjectConfigurator,
        sketchplanState,
        setSketchplanState,
        toggleSketchplanState,
        distancesData,
        handleSetDistancesData,
        handelSetInitialDistanceData,
        transformControlMode,
        setTransformControlMode,
        distanceMeasurementsMode,
        setDistanceMeasurementsMode,
        isProjectHasUnsavedZone,
        isConfigDataChanged,
        getProjectConfigData,
        measurementSystem,
        getUsedObjectsAssetsIds,
        mode,
        setMode,
        isFPVModeUnlocked,
        setIsFPVModeUnlocked,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
