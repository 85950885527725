import isEqual from "lodash/isEqual";
import { Box, OutlinedInput, Typography, styled } from "@mui/material";

import {
  ObjectDataInterface,
  TowerDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

interface ObjectTowerGPSInputInterface {
  activeObjects: (TowerDataInterface | ObjectDataInterface)[];
}

const ObjectTowerGPSInput = ({
  activeObjects,
}: ObjectTowerGPSInputInterface) => {
  const allObjectCoordinatesAreEqual =
    !!activeObjects.length &&
    activeObjects.every(
      item => item && isEqual(item.coordinates, activeObjects[0].coordinates)
    );

  const latitudeValue =
    allObjectCoordinatesAreEqual && activeObjects[0].coordinates
      ? activeObjects[0].coordinates.latitude.toFixed(7)
      : "";

  const longitudeValue =
    allObjectCoordinatesAreEqual && activeObjects[0].coordinates
      ? activeObjects[0].coordinates.longitude.toFixed(7)
      : "";

  return (
    <Wrapper>
      <Label
        variant="body14Regular"
        isactiveobject={activeObjects.length ? "true" : "fasle"}
      >
        GPS:
      </Label>
      <StyledOutlinedInput
        readOnly
        disabled={true}
        type="number"
        value={latitudeValue}
        placeholder={activeObjects.length ? "..." : ""}
      />
      <StyledOutlinedInput
        readOnly
        disabled={true}
        type="number"
        value={longitudeValue}
        placeholder={activeObjects.length ? "..." : ""}
      />
    </Wrapper>
  );
};

export default ObjectTowerGPSInput;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const Label = styled(Typography)<{ isactiveobject: "true" | "fasle" }>(
  ({ theme, isactiveobject }) => ({
    color:
      isactiveobject === "true"
        ? theme.palette["base-label"]
        : theme.palette["grey-dark"],
  })
);

const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  width: "100px",
  "& input::-webkit-inner-spin-button": {
    display: "none !important",
  },
}));
