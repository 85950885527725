import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import type { GetCustomerQuery, GetCustomerQueryVariables } from "../../API";
import { GET_CUSTOMER } from "../operations/queries";
import { useCustomerIdGuard } from "./useCustomerIdGuard";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import { RoleEnum } from "../models/enums";

export const useGetCustomer = () => {
  const customerId = useCustomerIdGuard();

  const { customerId: auditorCustomerId, role } = useAuthenticatedUser();

  const customerIdValue =
    role === RoleEnum.VERIFIER ? auditorCustomerId : customerId;

  const { data, loading } = useQuery<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >(GET_CUSTOMER, {
    skip: !customerIdValue,
    variables: {
      customerId: customerIdValue,
    },
    fetchPolicy: "network-only",
    onError: error => {
      console.error("GetCustomer", error);
    },
  });

  const customer = useMemo(() => data?.getCustomer as any, [data?.getCustomer]);

  return { customer, loading } as const;
};
