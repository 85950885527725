import { useState } from "react";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

export interface TextStateInterface {
  value: string;
  error: boolean;
  errorMessage: string;
  trackError: boolean;
}

export interface UseTextInputStateInterface {
  onChange: (text: string) => boolean;
  resetState: (newState?: TextStateInterface) => void;
  state: TextStateInterface;
}

export const useTextInputState = (
  defaultState: TextStateInterface,
  // eslint-disable-next-line @typescript-eslint/ban-types
  validation?: Function
): UseTextInputStateInterface => {
  const [state, setState] = useState<TextStateInterface>(defaultState);

  const doValidation = (value: string): ValidationResponseInterface => {
    if (!validation || typeof validation !== "function") {
      return { error: false, errorMessage: "" };
    }

    return validation(value);
  };

  const onChange = (value: string): boolean => {
    const { error, errorMessage } = doValidation(value);

    setState({
      value: value,
      error,
      errorMessage,
      trackError: true,
    });

    return error;
  };

  const resetState = (newState?: TextStateInterface): void => {
    setState(newState || defaultState);
  };

  return { onChange, state, resetState };
};
