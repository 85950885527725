import { useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";

import NumberInput from "../../input/NumberInput";

import { useNumberInputState } from "../../../hooks/useNumberInputState";
import { useGetValueInCurrentMeasurementSystem } from "../../../hooks/useGetValueInCurrentMeasurementSystem";

import { ProjectContext } from "../../../context";

import { TowerDataInterface } from "../../../context/ProjectContext/ProjectEntitesTypes";

import { numberValidationRangeAndMultipleCheck } from "../../../validation";

interface TowerHeightInputInterface {
  activeTowers: TowerDataInterface[];
}

const TowerHeightInput = ({
  activeTowers,
}: TowerHeightInputInterface): JSX.Element => {
  const { measurementSystem } = useContext(ProjectContext);
  const { getHeightValueFromFoot, getHeightValueFromData } =
    useGetValueInCurrentMeasurementSystem(measurementSystem);

  const towerHeight = useNumberInputState(
    {
      value: `${getHeightValueFromFoot(44)}`,
      step: getHeightValueFromFoot(1),
      min: getHeightValueFromFoot(20),
      max: getHeightValueFromFoot(60),
      error: false,
      errorMessage: "",
      trackError: false,
    },
    numberValidationRangeAndMultipleCheck
  );

  const onTowerHeightInputChange = (value: string) => {
    towerHeight.onChange(value);
  };

  useEffect(() => {
    if (activeTowers.length) {
      const allEqual = activeTowers.every(
        item => item && item.height === activeTowers[0].height
      );

      towerHeight.onChange(
        allEqual && activeTowers[0].height
          ? getHeightValueFromData(activeTowers[0].height).toString()
          : ""
      );
    }
  }, [activeTowers, measurementSystem]);

  return (
    <Wrapper>
      <NumberInput
        disabled={true}
        viewType="row"
        labelWidth="50px"
        inputWidth="60px"
        label="Height:"
        readonly
        value={towerHeight.state.value}
        placeholder="..."
        handleOnChange={onTowerHeightInputChange}
        step={towerHeight.state.step}
        min={towerHeight.state.min}
        max={towerHeight.state.max}
      />
    </Wrapper>
  );
};

export default TowerHeightInput;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));
