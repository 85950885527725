import { useState } from "react";

import { readFileAsDataURL } from "../utils";

interface ValidationResponseInterface {
  error: boolean;
  errorMessage: string;
}

export interface FileStateInterface {
  file: File | null | undefined;
  fileData: string | null | ArrayBuffer;
  error: boolean;
  errorMessage: string;
  trackError: boolean;
  fileKey?: string;
}

export interface UseUploadFileStateInterface {
  onLoadFile: (file: File) => Promise<boolean>;
  resetState: () => void;
  state: FileStateInterface;
  loading: boolean;
}

export const useUploadFileState = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  validation?: Function,
  fileKey?: string,
  file?: File
): UseUploadFileStateInterface => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<FileStateInterface>({
    file,
    fileData: null,
    error: false,
    errorMessage: "",
    trackError: false,
    fileKey,
  });

  const doValidation = async (
    file: File
  ): Promise<ValidationResponseInterface> => {
    if (!validation || typeof validation !== "function") {
      return { error: false, errorMessage: "" };
    }

    return await validation(file);
  };

  const onLoadFile = async (file: File): Promise<boolean> => {
    setLoading(true);

    const fileData = await readFileAsDataURL(file);
    const { error, errorMessage } = await doValidation(file);

    setState({
      ...state,
      file,
      fileData,
      error,
      errorMessage,
      trackError: false,
    });

    setLoading(false);

    return error;
  };

  const resetState = (): void => {
    setState({
      file: null,
      fileData: null,
      error: false,
      errorMessage: "",
      trackError: false,
      fileKey,
    });

    setLoading(false);
  };

  return { onLoadFile, resetState, state, loading };
};
