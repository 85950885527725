import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import "./index.css";
import env from "../../../environment.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const envName = env.name;

async function startApp() {
  if (!isProduction()) {
    await tryLoadAndStartRecorder({
      projectId: "lsFr24sbZuIEYhfCMAEhCXgSWj4H0zgaEtwAoDoz",
      isProduction: false,
    });
  }

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

function isProduction() {
  return envName === "prod";
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
