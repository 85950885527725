import { useContext, useEffect, useState } from "react";
import { Box, styled } from "@mui/material";

import NumberInput from "../../input/NumberInput";

import { useNumberInputState } from "../../../hooks/useNumberInputState";

import {
  ConfiguratorPermissionContext,
  ProjectContext,
} from "../../../context";

import {
  EntityTypeEnum,
  ObjectDataInterface,
  TowerDataInterface,
} from "../../../context/ProjectContext/ProjectEntitesTypes";

import { numberValidationRangeAndMultipleCheck } from "../../../validation";

interface ObjectTowerAngleInputInterface {
  activeObjects: (TowerDataInterface | ObjectDataInterface)[];
}

const ObjectTowerAngleInput = ({
  activeObjects,
}: ObjectTowerAngleInputInterface): JSX.Element => {
  const {
    threeScene,
    isLoading,
    objects,
    towers,
    updateObjectTowerState,
    distanceMeasurementsMode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const [isChangedByInput, setIsChangedByInput] = useState(false);

  const objectAngle = useNumberInputState(
    {
      value: "0",
      step: 10,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: false,
    },
    numberValidationRangeAndMultipleCheck
  );

  const onObjectAngleInputChange = (value: string) => {
    setIsChangedByInput(true);

    objectAngle.onChange(value);
  };

  useEffect(() => {
    if (
      activeObjects[0] &&
      !objectAngle.state.error &&
      objectAngle.state.value !== "" &&
      isChangedByInput
    ) {
      const objectsToUpdate = activeObjects.filter(
        obj => obj.angle?.toString() !== objectAngle.state.value
      );

      objectsToUpdate.forEach(obj => {
        threeScene.sceneFacility3dObjects.setObjectQuaternionByAngle(
          obj.id,
          objectAngle.state.value
        );
      });

      updateObjectTowerState(objectsToUpdate.map(obj => obj.id));
    }
  }, [objectAngle.state]);

  const resetAngleState = (value: string) => {
    const isActiveObjectsIncludesTower =
      !!activeObjects.length &&
      activeObjects.find(item => item.EntityType === EntityTypeEnum.Tower);

    setIsChangedByInput(false);

    objectAngle.resetState({
      value: value,
      step: isActiveObjectsIncludesTower ? 10 : 5,
      min: -180,
      max: 180,
      error: false,
      errorMessage: "",
      trackError: true,
    });
  };

  useEffect(() => {
    if (activeObjects.length) {
      const allEqual = activeObjects.every(
        item => item && item.angle === activeObjects[0].angle
      );

      if (allEqual) {
        const lastElement = activeObjects.slice(-1)[0];

        const newAngleValue =
          lastElement &&
          (lastElement.angle || lastElement.angle === 0) &&
          lastElement.angle.toString();

        if (newAngleValue) {
          resetAngleState(newAngleValue);
        }
      } else {
        resetAngleState("");
      }
    }
  }, [activeObjects, objects, towers]);

  const disabled =
    !activeObjects.length ||
    distanceMeasurementsMode ||
    isLoading ||
    !isAllowEditing;

  return (
    <Wrapper>
      <NumberInput
        disabled={disabled}
        viewType="row"
        labelWidth="50px"
        inputWidth="60px"
        label="Angle:"
        value={activeObjects.length ? objectAngle.state.value : ""}
        placeholder={activeObjects.length ? "..." : ""}
        handleOnChange={onObjectAngleInputChange}
        step={objectAngle.state.step}
        min={objectAngle.state.min}
        max={objectAngle.state.max}
        isError={
          !!activeObjects.length &&
          objectAngle.state.value !== "" &&
          objectAngle.state.error
        }
      />
    </Wrapper>
  );
};

export default ObjectTowerAngleInput;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));
