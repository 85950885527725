import { useState } from "react";

import Notification, {
  NotificationInterface,
} from "../../components/Notification";

import { NotificationContext } from "./NotificationContext";

export const NotificationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [notificationData, setNotificationData] =
    useState<NotificationInterface | null>(null);

  const openNotification = (data: NotificationInterface) => {
    if (notificationData) {
      closeNotification();
    }

    setNotificationData(data);
  };

  const closeNotification = () => setNotificationData(null);

  return (
    <NotificationContext.Provider
      value={{
        notificationData,
        openNotification,
        closeNotification,
      }}
    >
      {children}

      {notificationData && (
        <Notification
          title={notificationData.title}
          message={notificationData.message}
          severity={notificationData.severity}
        />
      )}
    </NotificationContext.Provider>
  );
};
