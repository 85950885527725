import { useState } from "react";

import AddCameraModal from "../../components/AddCameraModal";

import { AddCameraContext, TowerSideDataInterface } from "./AddCameraContext";

export const AddCameraContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [towerSideData, setTowerSideData] =
    useState<TowerSideDataInterface | null>(null);

  const openAddCameraModal = (data: TowerSideDataInterface) => {
    if (data) {
      closeAddCameraModal();
    }

    setTowerSideData(data);
  };

  const closeAddCameraModal = () => setTowerSideData(null);

  return (
    <AddCameraContext.Provider
      value={{
        towerSideData,
        openAddCameraModal,
        closeAddCameraModal,
      }}
    >
      {children}

      {towerSideData && (
        <AddCameraModal
          onClose={closeAddCameraModal}
          towerSideData={towerSideData}
        />
      )}
    </AddCameraContext.Provider>
  );
};
